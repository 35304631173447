/* eslint-disable import/extensions */
import {configureStore} from '@reduxjs/toolkit';

import {authSlice} from '../reducers/auth';
import {configSlice} from '../reducers/configuracoes';
import {filiaisSlice} from '../reducers/filiiais';
import {sincSlice} from '../reducers/sinc';
import {uiSlice} from '../reducers/ui';
import {vendedoresSlice} from '../reducers/vendedores';
import {visitaSlice} from '../reducers/visita';
import {pedidoSlice} from '../reducers/pedido';
import {notasSlice} from '../reducers/notas';
import {clienteStatusSlice} from '../reducers/statusCliente';
import {atividadeClienteSlice} from '../reducers/atividadeCliente';
import {grupoSlice} from '../reducers/grupo';
import {subgrupoSlice} from '../reducers/subgrupo';
import {produtoStatusSlice} from '../reducers/status';

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        ui: uiSlice.reducer,
        vendedores: vendedoresSlice.reducer,
        filiais: filiaisSlice.reducer,
        visita: visitaSlice.reducer,
        config: configSlice.reducer,
        sinc: sincSlice.reducer,
        pedido: pedidoSlice.reducer,
        notas: notasSlice.reducer,
        grupo: grupoSlice.reducer,
        subgrupo: subgrupoSlice.reducer,
        produtoStatus: produtoStatusSlice.reducer,
        clienteStatus: clienteStatusSlice.reducer,
        atividadeCliente: atividadeClienteSlice.reducer
    }
});

export default store;
