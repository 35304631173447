import {createTheme} from '@mui/material/styles';
import {blue, green, lightBlue, orange, red, grey} from '@mui/material/colors';

const customTheme = createTheme({
    palette: {
        primary: {
            main: blue[900]
        },
        secondary: {
            main: '#ffa600'
        },
        typography: {
            main: grey[50]
        },
        sucess: {
            main: green[500]
        },
        info: {
            main: lightBlue[500]
        },
        warning: {
            main: orange[500]
        },
        danger: {
            main: red[500]
        },
        graphic: [
            '#0D47A1',
            '#1360e2',
            '#1e96fc',
            '#60b6fb',
            '#a2d6f9',
            '#fcff7e',
            '#fcf300',
            '#fedd00',
            '#ffc600',
            '#ffa517'
        ],
        graphicContrast: [
            '#0D47A1',
            '#ffa517',
            '#1360e2',
            '#ffc600',
            '#1e96fc',
            '#fedd00',
            '#60b6fb',
            '#fcf300',
            '#a2d6f9',
            '#fcff7e'
        ]
    }
});

export default customTheme;
