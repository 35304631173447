import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {Provider} from 'react-redux';
import {toast} from 'react-toastify';
import store from './store/store';
import Dashboard from './App';
import './index.scss';
import './i18n';
import * as serviceWorker from './serviceWorker';

toast.configure({
    autoClose: 3000,
    draggable: false,
    position: 'top-right',
    hideProgressBar: false,
    newestOnTop: true,
    closeOnClick: true,
    rtl: false,
    pauseOnVisibilityChange: true,
    pauseOnHover: true
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Dashboard />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
serviceWorker.unregister();
