import {useState} from 'react';

/**
 * @param {string} idElement
 * @returns {{width: number}}
 */
const useResizeObserver = (idElement) => {
    const [width, setWidth] = useState();

    const resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
            if (Array.isArray(entry?.contentBoxSize)) {
                setWidth(entry.contentBoxSize[0].inlineSize);
            }
        });
    });
    const element = document.getElementById(idElement);

    if (element instanceof Element) resizeObserver.observe(element);

    return {
        width
    };
};

export default useResizeObserver;
