/* eslint-disable prettier/prettier */
import React, {Suspense, lazy} from 'react';
import {Switch} from 'react-router-dom';
import LoadingSIG from '../../../components/loading';
import PrivateRoute from '../../../components/router/PrivateRoute';

const ConfigAdmin = () => {
    const Configuracoes = lazy(() => import('../../../pages/configuracoes'));
    return <Configuracoes admin />;
};

export const SuporteRoute = () => {
    const Empresas = lazy(() => import('../../../pages/empresas'));
    const CadastrarEmpresa = lazy(() => import('../../../pages/empresas/CadastrarEmpresa'));
    const Integracoes = lazy(() => import('../../../pages/integracoes'));
    const CadastrarIntegracoes = lazy(() => import('../../../pages/integracoes/CadastrarIntegracoes'));
    const ElementosIntegracao = lazy(() => import('../../../pages/elementos-integracao'));
    const CadastrarElementoIntegracao = lazy(() => import('../../../pages/elementos-integracao/CadastrarElementos'));
    const Relatorios = lazy(() => import('../../../pages/relatorios/Relatorios'));
    const CadastroTipoGrafico = lazy(() => import('../../../pages/relatorios/CadastroTipoGrafico'));
    const CadastrarGrupo = lazy(() => import('../../../pages/relatorios/CadastrarGrupo'));
    const Grupos = lazy(() => import('../../../pages/relatorios/Grupos'));
    const ServicoDasEmpresas = lazy(() => import('../../../pages/servico-das-empresas'));
    const TipoGrafico = lazy(() => import('../../../pages/relatorios/TipoGrafico'));
    const NotificarVersao = lazy(() => import('../../../pages/versoes/notificar'));
    const Versao = lazy(() => import('../../../pages/versoes'));
    const CadastrarRelatorio = lazy(() => import('../../../pages/relatorios/CadastrarRelatorio'));
    const AlterarForcaDeSenha = lazy(() => import('../../../pages/configuracoes/AlterarSenha'));
    const LogSincronismoPage = lazy(() => import('../../../pages/sincronismo-afv/log/LogSincronismoPage'));

    return (
        <Suspense fallback={<LoadingSIG />}>
            <Switch>
                {/* Use '/a' no path para indicar o painel de administração */}
                <PrivateRoute exact path="/a/empresas" component={Empresas} />
                <PrivateRoute path="/a/empresas/:cod" component={CadastrarEmpresa} />
                <PrivateRoute exact path="/a/integracoes" component={Integracoes} />
                <PrivateRoute path="/a/integracoes/:cod" component={CadastrarIntegracoes} />
                <PrivateRoute exact path="/a/elementos-integracao" component={ElementosIntegracao} />
                {/* Para edicao do cadastro de elemebtos, neste endpoint, precisa ser informado
                 o codigo da empresa, o codigo do integrador e o codigo da integracao  */}
                <PrivateRoute path="/a/elementos-integracao/:codEmp/:codTipo/:codInteg" component={CadastrarElementoIntegracao} />
                <PrivateRoute exact path="/a/relatorios" component={Relatorios} />
                <PrivateRoute path="/a/relatorios/:cod" component={CadastrarRelatorio} />
                <PrivateRoute path="/a/graficos/:cod" component={CadastroTipoGrafico} />
                <PrivateRoute exact path="/a/graficos" component={TipoGrafico}/>
                <PrivateRoute exact path="/a/grupos" component={Grupos} />
                <PrivateRoute path="/a/grupos/:cod" component={CadastrarGrupo} />
                <PrivateRoute path="/a/servico-das-empresas" component={ServicoDasEmpresas} />
                <PrivateRoute exact path="/a/versoes" component={Versao} />
                <PrivateRoute path="/a/versoes/:cod" component={NotificarVersao} />
                <PrivateRoute exact path="/a/configuracoes" component={ConfigAdmin} />
                <PrivateRoute path="/a/alterar-senha" component={AlterarForcaDeSenha} />
                <PrivateRoute path="/logs" component={LogSincronismoPage} />
                <PrivateRoute />
            </Switch>
        </Suspense>
    );
};
