/* eslint-disable consistent-return */
import * as yup from 'yup';
import {validateCNPJ, validatePhone} from 'validations-br';

const useYupValidation = () => {
    const email = yup
        .string('Insira seu email')
        .email('Insira um email valido')
        .required('Email é obrigatório');

    // const password = yup
    //     .string('Insira sua senha')
    //     .matches(
    //         /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/g,
    //         'A senha deve ter no mínimo 8 caracteres, letra maiúscula, minúscula, número e caractere especial.'
    //     )
    //     .required('A senha é obrigatória');

    const password = yup
        .string('Insira sua senha')
        .matches(
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g,
            'A senha deve ter no mínimo 8 caracteres, letra maiúscula, minúscula, número e caractere especial.'
        )
        .required('A senha é obrigatória');

    const confirmPassword = (ref = 'password') => {
        return yup
            .string('Confirme sua senha')
            .oneOf([yup.ref(ref), null], 'As senhas não coincidem')
            .required('Confirme a senha');
    };

    const confirmPasswordById = (id = 'password') => {
        return yup
            .string()
            .test('confirm-password', 'As senhas não coincidem', (value) => {
                const confirmPass = document.getElementById(id);
                if (confirmPass !== null && value !== confirmPass.value) {
                    return false;
                }
                return true;
            });
    };

    const cnpj = yup
        .string()
        .test('is-cnpj', 'CNPJ invalido', (value) => validateCNPJ(value));

    const requiredTel = yup
        .string()
        .test('is-telefone', 'Telefone invalido', (value) => {
            if (value === undefined) return;
            return validatePhone(value);
        });

    const optionalTel = yup
        .string()
        .default('')
        .test('is-telefone', 'Telefone invalido', (value) => {
            if (value === undefined) return true;
            if (value === '') return true;
            return validatePhone(value);
        });

    const requiredStringField = (value) => {
        return yup.string(`Insira ${value}`).required(`${value} é obrigatório`);
    };

    const requiredNumberField = (value) => {
        return yup
            .string(`Insira ${value}`)
            .matches('[0-9]', `${value} invalido`)
            .required(`${value} é obrigatório`);
    };

    const requiredUrl = (value) => {
        return yup
            .string(`Insira ${value}`)
            .matches(
                '(www.)?' +
                    '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]' +
                    '{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)',
                'URL invalida. Ex. de URL valida: https://sig2000.com.br'
            )
            .required('URL é obrigatório');
    };

    const optionalUrl = (value) => {
        return yup
            .string(`Insira ${value}`)
            .matches(
                '(www.)?' +
                    '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]' +
                    '{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)',
                'URL invalida. Ex. de URL valida: https://sig2000.com.br'
            );
    };

    const requiredIpAdress = yup
        .string(`Insira o endereço de IP`)
        .matches(
            '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$',
            'Endereço de IP invalido. Ex. de IP válido: 127.0.0.1'
        )
        .required('Endereço de IP obrigatório.');

    return {
        email,
        password,
        confirmPassword,
        confirmPasswordById,
        cnpj,
        requiredTel,
        optionalTel,
        requiredIpAdress,
        requiredStringField,
        requiredNumberField,
        requiredUrl,
        optionalUrl
    };
};

export default useYupValidation;
