import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import translationPT from './locales/pt/translation.json';

// the translations
const resources = {
    pt: {
        translation: translationPT
    }
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'pt',
        useSuspense: true,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            useSuspense: true
        }
    });

export default i18n;
