import * as React from 'react';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import {Link} from 'react-router-dom';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import QuizIcon from '@mui/icons-material/Quiz';
import NotesIcon from '@mui/icons-material/Notes';
import useListaServicoDasEmpresas from '../../hooks/servico-das-empresas';
import {useAuth} from '../../hooks/useAuth';
import CustomText from '../mui/typography/CustomText';
import {CONFIG_PATH, CONFIG_PATH_ADM} from '../../constants';
import useBreakpoint from '../../hooks/responsividade/useBreakpoint';
import useVersao from '../../hooks/versao';

export default function MenuConta() {
    const {data} = useListaServicoDasEmpresas();
    const {about} = useVersao();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {isSmartphone} = useBreakpoint();
    const open = Boolean(anchorEl);
    const {logout, getSecretKey} = useAuth();
    const isCliente = JSON.parse(localStorage.getItem('isCliente')) ?? false;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const styledBoxDesktop = {
        display: 'flex',
        width: '150px',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        '.right-bar': {
            color: 'gray'
        },
        '& .MuiTypography-root': {margin: 0}
    };

    const styledBoxMobile = {
        display: 'flex',
        width: '28px',
        alignItems: 'center',
        justifyContent: 'flex-end',
        textAlign: 'center',
        '.right-bar': {
            color: 'gray'
        },
        '& .MuiTypography-root': {margin: 0}
    };

    const styleText = {
        margin: '0px 16px 0px 0px',
        display: 'flex',
        alignItems: 'center'
    };

    const styledPapers = {
        elevation: 0,
        sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
            },
            '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
            }
        }
    };

    const nomeDaEmpresa =
        localStorage.getItem('nome_fantasia') === null ||
        localStorage.getItem('nome_fantasia') === undefined ||
        localStorage.getItem('nome_fantasia') === 'undefined'
            ? localStorage.getItem('razao_social')
            : localStorage.getItem('nome_fantasia');

    const idDaEmpresa =
        JSON.parse(localStorage.getItem('info_user'))?.empresa || '';

    const MenuDeskTop = (
        <>
            <Box sx={styledBoxDesktop}>
                <MenuItem
                    className="right-bar"
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        padding: 0,
                        background: 'none'
                    }}
                >
                    <span
                        style={{
                            marginRight: '10px',
                            cursor: 'pointer',
                            background: 'none',
                            '&:hover': {
                                color: 'blue',
                                textDecoration: 'underline'
                            }
                        }}
                    />
                </MenuItem>

                <Link
                    className="right-bar"
                    to="/atualizacoes"
                    style={{marginRight: '5px'}}
                >
                    <Tooltip title="Notas de Atualização">
                        <NotesIcon />
                    </Tooltip>
                </Link>
                <Link className="right-bar" to="/faq">
                    <Tooltip title="FAQ">
                        <QuizIcon />
                    </Tooltip>
                </Link>
                <Link
                    className="right-bar"
                    to={{
                        pathname:
                            'https://servidorseguro.mysuite.com.br/client/login.php?sl=si2'
                    }}
                    target="_blank"
                    style={{color: 'black', opacity: 0.5}}
                >
                    <Tooltip title="Helpdesk MySuite">
                        <SupportAgentIcon />
                    </Tooltip>
                </Link>
                <Tooltip title="Configurações Gerais">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <AccountCircleIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={styledPapers}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                sx={{'& .MuiPaper-root': {left: '1300px', width: '248px'}}}
            >
                <MenuItem>
                    <Tooltip title={`${nomeDaEmpresa} - ${idDaEmpresa}`}>
                        <div style={{display: 'flex'}}>
                            <ListItemIcon>
                                <AccountCircleIcon fontSize="small" />
                            </ListItemIcon>
                            {nomeDaEmpresa?.length > 7
                                ? `${nomeDaEmpresa.substring(0, 15)}...`
                                : nomeDaEmpresa}
                        </div>
                    </Tooltip>
                </MenuItem>
                <Link to={isCliente ? CONFIG_PATH : CONFIG_PATH_ADM}>
                    <MenuItem sx={{color: 'black'}}>
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        Configurações
                    </MenuItem>
                </Link>
                <MenuItem onClick={getSecretKey}>
                    <ListItemIcon>
                        <SwapHorizIcon fontSize="small" />
                    </ListItemIcon>
                    ManagerV1
                </MenuItem>
                <MenuItem onClick={() => about()}>
                    <ListItemIcon>
                        <InfoIcon fontSize="small" />
                    </ListItemIcon>
                    Sobre
                </MenuItem>
                <MenuItem onClick={() => logout()}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Sair
                </MenuItem>
            </Menu>
        </>
    );

    const MenuMobile = (
        <>
            <Box sx={styledBoxMobile}>
                <Tooltip title="Configurações Gerais">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <AccountCircleIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={styledPapers}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem>
                    <Tooltip title={`${nomeDaEmpresa} - ${idDaEmpresa}`}>
                        <div style={{display: 'flex'}}>
                            <ListItemIcon>
                                <AccountCircleIcon fontSize="small" />
                            </ListItemIcon>
                            {nomeDaEmpresa?.length > 7
                                ? `${nomeDaEmpresa.substring(0, 15)}...`
                                : nomeDaEmpresa}
                        </div>
                    </Tooltip>
                </MenuItem>
                <MenuItem onClick={getSecretKey}>
                    <ListItemIcon>
                        <SwapHorizIcon fontSize="small" />
                    </ListItemIcon>
                    ManagerV1
                </MenuItem>
                <Link className="right-bar" to="/atualizacoes">
                    <MenuItem sx={{color: 'black'}}>
                        <ListItemIcon>
                            <NotesIcon />
                        </ListItemIcon>
                        Notas de Atualização
                    </MenuItem>
                </Link>
                <Link className="right-bar" to="/faq">
                    <MenuItem sx={{color: 'black'}}>
                        <ListItemIcon>
                            <QuizIcon />
                        </ListItemIcon>
                        FAQ
                    </MenuItem>
                </Link>
                <Link
                    className="right-bar"
                    to={{
                        pathname:
                            'https://servidorseguro.mysuite.com.br/client/login.php?sl=si2'
                    }}
                    target="_blank"
                >
                    <MenuItem sx={{color: 'black'}}>
                        <ListItemIcon>
                            <SupportAgentIcon />
                        </ListItemIcon>
                        Helpdesk MySuite
                    </MenuItem>
                </Link>
                <Link to={isCliente ? CONFIG_PATH : CONFIG_PATH_ADM}>
                    <MenuItem sx={{color: 'black'}}>
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        Configurações
                    </MenuItem>
                </Link>
                <MenuItem onClick={() => about()}>
                    <ListItemIcon>
                        <InfoIcon fontSize="small" />
                    </ListItemIcon>
                    Sobre
                </MenuItem>
                <MenuItem onClick={() => logout()}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Sair
                </MenuItem>
            </Menu>
        </>
    );

    return (
        <>
            <CustomText sx={styleText}>{data.cliente}</CustomText>
            {isSmartphone ? MenuMobile : MenuDeskTop}
        </>
    );
}
