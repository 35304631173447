import React from 'react';
import Languages from './languages-dropdown/LanguagesDropdown';
import MenuConta from '../../../components/menu-conta';

const Header = ({toggleMenuSidebar}) => {
    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <button
                        onClick={() => toggleMenuSidebar()}
                        type="button"
                        className="nav-link"
                        id="menu-button"
                    >
                        <i className="fas fa-bars" />
                    </button>
                </li>
            </ul>
            <ul className="navbar-nav ml-auto" style={{marginRight: '16px'}}>
                <div style={{display: 'none'}}>
                    <Languages />
                </div>
                <MenuConta />
            </ul>
        </nav>
    );
};

export default Header;
