import Swal from 'sweetalert2';
import * as data from './atualizacoes.json';

const useUIDeAtualizacao = () => {
    const atualizacoesMap = new Map();

    data.atualizacoes.forEach((atualizacao) => {
        atualizacoesMap.set(
            Object.keys(atualizacao)[0],
            Object.values(atualizacao)[0].join().replace(/<br>,/g, '<br>')
        );
    });

    const setAtualizacoesLocal = () => {
        if (
            localStorage.getItem('atualizacoes') === null ||
            // eslint-disable-next-line prettier/prettier
            new Date(localStorage.getItem('att_date')) < new Date(data.limitDate)
        ) {
            localStorage.setItem(
                'atualizacoes',
                data.menus.toString().replace(/'/g, '"')
            );
            localStorage.setItem('att_date', new Date(data.limitDate));
        }
    };

    /**
     * @param {string} menu
     * @returns {boolean}
     */
    const containsMenu = (menu) => {
        const atualizacoes = localStorage.getItem('atualizacoes');
        if (typeof atualizacoes === 'string') {
            if (atualizacoes.includes(`"${menu}"`)) return true;
        }
        return false;
    };

    /**
     * @param {string} menu
     * @returns {void}
     */
    const getAtualizacoes = (menu = '108') => {
        const att = localStorage.getItem('atualizacoes');
        if (att !== null) {
            if (localStorage.getItem('atualizacoes').includes(`"${menu}"`)) {
                localStorage.setItem('atualizacoes', att.replace(menu, ''));
                const sweetAlertOptions = {
                    title: 'Notas de atualização',
                    html: atualizacoesMap.get(menu),
                    confirmButtonColor: 'green',
                    confirmButtonText: 'Entendi',
                    width: '600px'
                };
                if (atualizacoesMap.get(menu) !== undefined)
                    setTimeout(() => {
                        Swal.fire(sweetAlertOptions);
                    }, 500);
            }
        }
    };

    return {
        containsMenu,
        getAtualizacoes,
        setAtualizacoesLocal
    };
};

export default useUIDeAtualizacao;
