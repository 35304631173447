import React from 'react';
import './forgot-pass.css';
import {Link, withRouter} from 'react-router-dom';

import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {CardMedia, Grid} from '@mui/material';
import Paper from '@mui/material/Paper';
import RedeSocial from '../../components/Rede-social';
import Input from '../../components/input/Input';
import img from '../../assets/images/logo.png';
import useRecuperarSenha from '../../hooks/configuracoes/useRecuperarSenha';
import CustomButton from '../../components/mui/button/CustomButton';

const ForgotPass = () => {
    const {formik} = useRecuperarSenha();

    return (
        <div className="pass-container">
            <div className="pass-card">
                <Paper
                    elevation={3}
                    sx={{
                        padding: '20px',
                        borderRadius: '10px'
                    }}
                >
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        width="480px"
                        sx={{
                            maxWidth: '300px',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <CardMedia
                            sx={{
                                marginTop: '20px',
                                maxWidth: '150px'
                            }}
                            component="img"
                            src={img}
                        />
                        <form
                            style={{marginTop: '20px'}}
                            noValidate
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="text-input">
                                <Input
                                    icon={faEnvelope}
                                    placeholder="Informe o email cadastrado"
                                    type="email"
                                    formik={formik}
                                    formikFieldProps={formik.getFieldProps(
                                        'email'
                                    )}
                                />
                            </div>
                            <br />
                            <div>
                                <CustomButton
                                    className="recovery-button"
                                    type="submit"
                                    sx={{marginTop: '10px', width: '100%'}}
                                >
                                    Enviar
                                </CustomButton>
                            </div>
                        </form>
                    </Grid>
                    <div style={{padding: '30px'}} className="return-login">
                        <Link to="/login">
                            <small>Retornar ao Login</small>
                        </Link>
                    </div>
                </Paper>
            </div>
            <div>
                <RedeSocial />
            </div>
        </div>
    );
};

export default withRouter(ForgotPass);
