import {useEffect, useState} from 'react';
import useAPI from '../../api/useAPI';
import {SERVICOS_PATH} from '../../constants';
import Columns from '../../constants/Columns';
import useFilter from '../filter/useFilter';

const useListaServicoDasEmpresas = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState();
    const {api} = useAPI();
    const {
        filter,
        setFilter,
        filteredData,
        setFilteredData,
        statusFilter,
        setStatusFilter
    } = useFilter(data);

    const getData = () => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken === null) return;
        api.get(SERVICOS_PATH).then((response) => {
            let dataRes;
            if (Array.isArray(response.data)) {
                dataRes = [...response.data];
                if (dataRes.length % 2 !== 0) {
                    dataRes.push({cliente: '-'});
                }
            } else if (typeof response.data === 'object') {
                dataRes = [{...response.data}];
            }
            setLoading(false);
            setData(dataRes);
            setFilteredData(dataRes);
        });
    };

    useEffect(() => {
        getData();
    }, []);

    return {
        columns: Columns.SERVICO_DAS_EMPRESAS,
        data,
        loading,
        filter,
        setFilter,
        filteredData,
        statusFilter,
        setStatusFilter
    };
};

export default useListaServicoDasEmpresas;
