import {useFormik} from 'formik';
import * as yup from 'yup';
import useAPI from '../../api/useAPI';
import {RECUPERACAO_SENHA_PATH} from '../../constants';
import useYupValidation from '../validation/useYupValidation';

const useRecuperarSenha = () => {
    const {api} = useAPI();
    const {email} = useYupValidation();

    const schema = yup.object({
        email
    });

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: schema,
        onSubmit: (value) => {
            api.post(
                RECUPERACAO_SENHA_PATH,
                value,
                'Um email foi enviado para recuperação de senha'
            );
        }
    });

    return {
        formik
    };
};

export default useRecuperarSenha;
