import React from 'react';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const TextStyled = styled(Typography)(() => ({
    paddingLeft: '8px',
    fontSize: '18px',
    marginTop: '30px'
}));

const CustomText = ({sx, children, ...props}) => {
    return (
        <TextStyled
            sx={(theme) => ({
                ...sx,
                [theme.breakpoints.down('sm')]: {
                    paddingLeft: '0px',
                    textAlign: sx?.textAlign ?? 'center'
                }
            })}
            {...props}
        >
            {children}
        </TextStyled>
    );
};

export default CustomText;
