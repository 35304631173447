import React, {useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import LoadingSIG from '../../components/loading';
import {useAuth} from '../../hooks/useAuth';

const Auth = () => {
    const {secretKey} = useParams();

    const history = useHistory();

    const {loginPost, loginError} = useAuth(false, false);

    useEffect(() => {
        loginPost({
            secret_key: secretKey
        });

        if (loginError !== undefined) {
            history.push('/login');
        }
    }, [loginError]);

    return <LoadingSIG loading />;
};

export default Auth;
