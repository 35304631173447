import React from 'react';
import {Link} from 'react-router-dom';
import './rede-social.css';
import {CardMedia} from '@mui/material';
import logoFb from '../../assets/images/logo-fb.png';
import logoIn from '../../assets/images/logo-in.png';
import logoIg from '../../assets/images/logo-ig.png';

export default function RedeSocial() {
    return (
        <div className="siga-redes">
            <br />
            <h1>Sigam nossas redes sociais</h1>
            <div className="card-goup">
                <div className="logo-rede">
                    <Link
                        to={{
                            pathname: 'https://www.facebook.com/sig2000software'
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <CardMedia
                            sx={{
                                marginTop: '-5px',
                                marginRight: '20px',
                                maxWidth: '30px',
                                maxHeight: '30px'
                            }}
                            component="img"
                            src={logoFb}
                        />
                    </Link>
                    <Link
                        to={{pathname: 'https://www.instagram.com/sig_2000/'}}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <CardMedia
                            sx={{
                                marginTop: '-5px',
                                maxWidth: '30px',
                                maxHeight: '30px',
                                Width: '30px',
                                Height: '30px'
                            }}
                            component="img"
                            src={logoIg}
                        />
                    </Link>
                    <Link
                        to={{
                            pathname:
                                'https://www.linkedin.com/company/sig2000-informatica/mycompany/'
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <CardMedia
                            sx={{
                                marginTop: '-5px',
                                marginLeft: '20px',
                                maxWidth: '30px',
                                maxHeight: '30px'
                            }}
                            component="img"
                            src={logoIn}
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
}
