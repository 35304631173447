import React, {useEffect} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import PrivacyPolicy from './modules/privacy-policy/PrivacyPolicy';
import Main from './modules/main/Main';
import ForgotPassword from './pages/forgot-password';
import {calculateWindowSize} from './utils/helpers';
import {useWindowSize} from './hooks/useWindowSize';
import {setWindowSize} from './reducers/ui';
import Login from './pages/login';
import NotFoundPage from './pages/notfound';
import PublicRoute from './components/PublicRoutes/PublicRoute';
import NovaSenha from './pages/forgot-password/NovaSenha';
import Auth from './pages/auth';

const RoutesApp = () => {
    const windowSize = useWindowSize();
    const screenSize = useSelector((state) => state.ui.screenSize);
    const dispatch = useDispatch();

    useEffect(() => {
        const size = calculateWindowSize(windowSize.width);
        if (screenSize !== size) {
            dispatch(setWindowSize(size));
        }
    }, [windowSize]);

    return (
        <div>
            <BrowserRouter>
                <Switch>
                    <PublicRoute exact path="/login">
                        <Login />
                    </PublicRoute>
                    <PublicRoute exact path="/recuperar-senha">
                        <ForgotPassword />
                    </PublicRoute>
                    <PublicRoute path="/recuperar-senha/:token">
                        <NovaSenha />
                    </PublicRoute>
                    <PublicRoute exact path="/politica-de-privacidade">
                        <PrivacyPolicy />
                    </PublicRoute>
                    <PublicRoute exact path="/auth/:secretKey">
                        <Auth />
                    </PublicRoute>
                    <PublicRoute path="/">
                        <Main />
                    </PublicRoute>
                    <Route path="*" component={NotFoundPage} />
                </Switch>
            </BrowserRouter>
        </div>
    );
};
export default RoutesApp;
