/* eslint-disable import/prefer-default-export */
import {useState, useEffect} from 'react';

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    useEffect(() => {
        /**
         * addEventListener foi comentado porque causava renderizações
         * desnecessárias em formulários no smartphone.
         */
        // window.addEventListener('resize', handleResize);
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
        // return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
};
