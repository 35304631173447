import React, {useEffect, useState} from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import icons from '../../modules/main/menu-sidebar/icons';

const useMenu = () => {
    const getMenu = () => JSON.parse(localStorage.getItem('menu')) ?? [];
    const [menuMap, setMenuMap] = useState(getMenu());

    useEffect(() => {
        let menu = getMenu();
        menu = menu
            .filter((m) => m.fk_menu === null)
            .map((m) => ({
                id: m.id,
                name: m.descricao,
                path: `/${m.url}`,
                icon: icons[m.extra] || <RadioButtonUncheckedIcon />,
                children: menu
                    .filter((c) => c.fk_menu === m.id)
                    .map((c) => ({
                        id: c.id,
                        name: c.descricao,
                        path: `/${c.url}`
                    }))
            }));
        setMenuMap(menu);
    }, []);

    return {
        menuMap
    };
};

export default useMenu;
