import React from 'react';
import RoutesApp from './routes';

export default function App() {
    return (
        <div>
            <RoutesApp />
        </div>
    );
}
