import {createSlice} from '@reduxjs/toolkit';

export const notasSlice = createSlice({
    name: 'nota',
    initialState: {
        status: {
            statusNotas: [],
            statusNotasIds: []
        }
    },
    reducers: {
        setNotasReducer: (state, action) => {
            state.status.statusNotas = action.payload;
        },
        setNotasIdsReducer: (state, action) => {
            state.status.statusNotasIds = action.payload;
        }
    }
});

export const {setNotasReducer, setNotasIdsReducer} = notasSlice.actions;
