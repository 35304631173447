import LRU from 'lru-cache';

const options = {
    maxAge: 1000 * 60 * 60
};

const lru = new LRU(options);

const lruCache = {
    /**
     * @param {string} key
     * @returns {any | undefined}
     */
    get(key) {
        return lru.get(key);
    },

    /**
     * @param {string} key
     * @param {any} value
     */
    set(key, value) {
        return lru.set(key, value);
    },

    /**
     * @param {string} key
     * @returns {any | undefined}
     */
    clear() {
        return lru.reset();
    }
};

export default lruCache;
