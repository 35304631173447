/* eslint-disable indent, no-console, prefer-const, prettier/prettier */
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

const TIMEOUT = 'Tempo de requisição esgotado. Tente novamente em instantes!';
const UNEXPECTED_ERROR = 'Ocorreu um erro inesperado. Tente novamente em instantes!';
const ERROR_5XX = 'Erro Interno do Servidor. Por favor, tente novamente mais tarde.';

const useHandleError = (fn403 = () => {}) => {
    const history = useHistory();
    const toastId = 'toast-error';

    const handle = (error) => {
       
        const {response} = error;
        let obj = {};

        let errorWithData = Object.prototype.hasOwnProperty.call(error, 'data');

        let responseWithData;
        if (response !== undefined) responseWithData = Object.prototype.hasOwnProperty.call(response, 'data');

        let codTimeout = 0;
        if (errorWithData) obj = error;
        if (responseWithData) obj = response;

        codTimeout = obj?.status;
        const isTimeout = codTimeout === 408 || codTimeout === 504;

        /* json com dados e com proprs de erro
        * response undefined
        * error: {data: }
        */

        /* json somente com os erros
        * response: {data: }
        * error: string
        */

        // testando se variável error(dados recebidos) contém a propriedade 'data'
        if (errorWithData && (JSON.stringify(error).includes('timeout')) && isTimeout) {
            console.error(error);
            toast.error(TIMEOUT, {toastId});
            throw error;
        }

        if (errorWithData && !JSON.stringify(error).includes('"status":200,"statusText":"OK"')) {
            console.error(error);
            toast.error(UNEXPECTED_ERROR);
        }

        switch (obj?.status) {
            case 200:
            case 400:
                toast.error(obj.data.message, {toastId});
                console.error(`${obj?.status || ''} ${obj?.statusText  || ''}`)
                throw error;
            case 401:
                fn403();
                history.push('/login');
                toast.error('Hmmmm, infelizmente não reconhecemos seu usuário. Altere e tente novamente.');
                console.error(`${obj?.status || ''} ${obj?.statusText  || ''}`)
                throw error;
            case 403:
                toast.info('Logout Automático ou seu perfil não tem permissão.');
                console.error(`${obj?.status || ''} ${obj?.statusText  || ''}`)
                throw error;
            case 404:
                toast.error('Dados não encontrados. Modifique os filtros e tente novamente.');
                console.error(`${obj?.status || ''} ${obj?.statusText  || ''}`)
                throw error;
            case 405:
                toast.error(obj.data.message, {toastId});
                console.error(`${obj?.status || ''} ${obj?.statusText  || ''}`)
                throw error;
            case 500:
                toast.error('Algo inesperado aconteceu. Se persistir, entre em contato com o suporte.');
                console.error(`${obj?.status || ''} ${obj?.statusText  || ''}`)
                throw error;
            default:
                toast.error(
                    `${obj?.status || ''} ${
                        obj?.statusText ||
                        'Algo inesperado aconteceu. Se persistir, entre em contato com o suporte.'
                    }`,
                    {
                        toastId
                });
                break;
        }

        let method = obj?.config?.method.toLowerCase();
        if (
            (method === 'post' || method === 'patch') &&
            obj.data.message !== undefined
        ) {
            toast.error(obj.data.message, {toastId});
            throw error;
        }
        // erro 5xx
        else if (obj?.status?.toString().startsWith('5')) {
            toast.error(ERROR_5XX, {toastId});
            throw error;
        }
    };

    return {
        handle
    };
};

export default useHandleError;
