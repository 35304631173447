import {useFormik} from 'formik';
import {useParams} from 'react-router-dom';
import * as yup from 'yup';
import useAPI from '../../api/useAPI';
import useYupValidation from '../validation/useYupValidation';

const useNovaSenha = () => {
    const {api} = useAPI();
    const {token} = useParams();
    const {password, confirmPassword} = useYupValidation();

    const tokenIsValid = () => {
        if (typeof token === 'string') {
            return token.startsWith('SIG') && token.length === 16;
        }
        return false;
    };

    const schema = yup.object({
        token: yup.string(),
        password,
        password_confirmation: confirmPassword()
    });

    const formik = useFormik({
        initialValues: {
            token,
            password: '',
            password_confirmation: ''
        },
        validationSchema: schema,
        onSubmit: (value) => {
            api.patch(
                'v1/resetPassword',
                value,
                'Nova senha definida com sucesso'
            );
        }
    });

    return {
        formik,
        tokenIsValid
    };
};

export default useNovaSenha;
