import {createSlice} from '@reduxjs/toolkit';

export const filiaisSlice = createSlice({
    name: 'filiais',
    initialState: {
        filiais: [],
        filiaisIds: []
    },
    reducers: {
        filiaisReducer: (state, action) => {
            state.filiais = action.payload;
        },
        filiaisIdsReducer: (state, action) => {
            state.filiaisIds = action.payload;
        }
    }
});

export const {filiaisReducer, filiaisIdsReducer} = filiaisSlice.actions;
