// eslint-disable-next-line no-unused-vars
import {SweetAlertOptions} from 'sweetalert2';
import customTheme from '../../theme/CustomTheme';

// @ts-check
/** @returns {SweetAlertOptions} */
const Sucessfully = (/** @type {string} */ title) => {
    return {
        title: `${title}`,
        icon: 'success',
        confirmButtonColor: customTheme.palette.primary.main,
        iconColor: customTheme.palette.success.main
    };
};

export default Sucessfully;
