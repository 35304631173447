import Swal from 'sweetalert2';
import useAPI from '../../api/useAPI';
import customTheme from '../../theme/CustomTheme';
import {version as projectVersion} from '../../../package.json';

const useVersao = () => {
    const {api} = useAPI();

    const about = () => {
        api.get('v1/about').then((res) => {
            const {version} = res.data;

            Swal.fire({
                html: `Projeto: v${projectVersion}
                <br>Manager (Web App): v${version.web_app}
                <br>Manager (APIs mobile, painel, externa): v${version.api}
                <br>AFV (App): v${version.afv2}`,
                icon: 'info',
                confirmButtonColor: customTheme.palette.primary.main,
                iconColor: customTheme.palette.success.main
            });
        });
    };

    return {
        about
    };
};

export default useVersao;
