import React from 'react';
import {styled} from '@mui/material/styles';
import CenterFlexBox from './CenterFlexBox';

const CenterColumnBox = ({height, children}) => {
    const Component = styled(CenterFlexBox)(() => ({
        height,
        flexDirection: 'column',
        alignItems: 'center'
    }));

    return <Component>{children}</Component>;
};

export default CenterColumnBox;
