import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {ALTERAR_SENHA_ROUTE_PATH, ROOT_ROUTE_PATH} from '../../constants';
import useMenu from '../../hooks/menu/useMenu';
import {useAuth} from '../../hooks/useAuth';

/**
 * @param {{
 *  component: Node,
 *  RouteProps
 * }} props
 */
const PrivateRoute = ({component: Component, ...rest}) => {
    const {path} = rest;
    const {isLoggedIn} = useAuth();

    return (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn() ? (
                    <RenderComponent
                        path={path}
                        component={<Component {...props} />}
                    />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

const RenderComponent = ({path, component}) => {
    const {menuMap} = useMenu();

    if (!path) return null;

    const containsPath = (value) => {
        if (value === ROOT_ROUTE_PATH || value === ALTERAR_SENHA_ROUTE_PATH)
            return true;

        // remove a '/' como primeiro caractere, divide o path pelo '/:' e pega a string no index 0
        const v =
            typeof value === 'string' &&
            value.substring(1, value.length).split('/:')[0];

        return JSON.stringify(menuMap).includes(v);
    };

    return containsPath(path) && component;
};

export default PrivateRoute;
