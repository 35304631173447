/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary, no-restricted-globals */
import React, {useEffect, useState} from 'react';
import {timeout} from '../../api/AxiosClient';
import logoSig from '../../assets/images/LogoSig.svg';
import useResizeObserver from '../../hooks/observer';
import useBreakpoint from '../../hooks/responsividade/useBreakpoint';
import CenterFlexBox from '../mui/wrapper/CenterFlexBox';
import './style.scss';

const LoadingSIG = ({loading}) => {
    const {isSmartphone} = useBreakpoint();
    const {width: observeWidth} = useResizeObserver('sidebar');

    return loading ? (
        <CenterFlexBox
            sx={{
                backgroundColor: 'rgba(255,255,255,0.7)',
                position: 'fixed',
                width: '100%',
                height: '100%',
                top: '0px',
                left: `${isSmartphone ? 0 : observeWidth / 2}px`,
                right: '0',
                bottom: '0'
            }}
        >
            <div className="loading">
                <img src={logoSig} alt="" />
            </div>
        </CenterFlexBox>
    ) : null;
};

export default LoadingSIG;
