/* eslint-disable indent */
/* eslint-disable no-unreachable */
import {useEffect, useState} from 'react';

/** @param {Array} data */
const useFilter = (data) => {
    const [filter, setFilter] = useState(String);
    const [filteredData, setFilteredData] = useState([]);
    const [statusFilter, setStatusFilter] = useState(String);

    if (!Array.isArray(data)) {
        data = [];
    }

    useEffect(() => {
        const stringFilter = data.filter((e) => {
            return JSON.stringify(e)
                .toString()
                .toLowerCase()
                .includes(filter.toLowerCase());
        });
        const radioFilter = stringFilter.filter((e) => {
            switch (statusFilter) {
                case 'true':
                    return e.status;
                case 'false':
                    // eslint-disable-next-line
                    return e.status == false || e.status === 'false'; // 0, false ou "false"
                default:
                    return true;
            }
        });
        setFilteredData(radioFilter);
    }, [filter, statusFilter]);

    return {
        filter,
        setFilter,
        filteredData,
        statusFilter,
        setStatusFilter,
        setFilteredData
    };
};

export default useFilter;
