import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadUser, logoutUser} from '../../reducers/auth';
import Header from './header/Header';
import Footer from './footer/Footer';
import MenuSidebar from './menu-sidebar/MenuSidebar';
import PageLoading from '../../components/page-loading/PageLoading';
import {ClienteRoute} from './routes/ClienteRoute';
import {SuporteRoute} from './routes/SuporteRoute';

const Main = () => {
    const dispatch = useDispatch();
    const screenSize = useSelector((state) => state.ui.screenSize);
    const [isAppLoaded, setIsAppLoaded] = useState(false);

    const resizeSidebar = () => {
        const sidebar = document.getElementById('main-sidebar');
        const root = document.getElementById('root');
        root.classList.remove(
            'sidebar-closed',
            'sidebar-collapse',
            'sidebar-open'
        );

        if (sidebar?.offsetWidth > 200 && screenSize === 'lg') {
            root.classList.add('sidebar-collapse');
        } else if (
            sidebar?.offsetWidth > 200 &&
            (screenSize === 'xs' || screenSize === 'sm')
        ) {
            root.classList.add('sidebar-open');
        } else if (sidebar?.offsetWidth > 200 && screenSize === 'md') {
            root.classList.remove('sidebar-open');
            root.classList.add('sidebar-collapse');
        } else if (sidebar?.offsetWidth < 100 && screenSize === 'md') {
            root.classList.remove('sidebar-collapse');
            root.classList.add('sidebar-open');
        } else if (sidebar?.offsetWidth < 100 && screenSize !== 'lg') {
            root.classList.add('sidebar-closed', 'sidebar-collapse');
        }
    };

    const handleToggleMenuSidebar = () => resizeSidebar();

    const fetchProfile = async () => {
        try {
            const userInfo = JSON.parse(localStorage.getItem('info_user'));
            const response = {
                name: userInfo.usuario,
                picture: null
            };
            dispatch(loadUser(response));
            setIsAppLoaded(true);
        } catch (error) {
            dispatch(logoutUser());
            setIsAppLoaded(true);
        }
    };

    useEffect(() => {
        document.getElementById('root').classList.remove('register-page');
        document.getElementById('root').classList.remove('login-page');
        document.getElementById('root').classList.remove('hold-transition');

        document.getElementById('root').classList.add('sidebar-mini');
        document.getElementById('root').classList.add('layout-fixed');

        fetchProfile();
        return () => {
            document.getElementById('root').classList.remove('sidebar-mini');
            document.getElementById('root').classList.remove('layout-fixed');
        };
    }, []);

    useEffect(() => resizeSidebar(), []);

    useEffect(() => {
        const root = document.getElementById('root');
        root.classList.remove(
            'sidebar-closed',
            'sidebar-collapse',
            'sidebar-open'
        );
        if (screenSize === 'sm' || screenSize === 'md') {
            root.classList.add('sidebar-collapse');
        }
    }, []);

    useEffect(() => {
        document.addEventListener('click', (event) => {
            if (document.offsetWidth > 480) return;

            const sidebar = document.getElementById('main-sidebar');
            const menuBtn = document.getElementById('menu-button');
            const root = document.getElementById('root');

            if (
                !sidebar?.contains(event.target) &&
                !menuBtn?.contains(event.target) &&
                root?.classList.contains('sidebar-open')
            ) {
                root.classList.remove('sidebar-open');
                root.classList.add('sidebar-collapse');
            }
        });
    }, []);

    const getAppTemplate = useCallback(() => {
        if (!isAppLoaded) {
            return <PageLoading />;
        }
        return (
            <>
                <Header toggleMenuSidebar={handleToggleMenuSidebar} />

                <MenuSidebar />

                <div className="content-wrapper">
                    <div className="pt-3" />
                    <section className="content">
                        <ClienteRoute />
                        <SuporteRoute />
                    </section>
                </div>
                <Footer />
                <div
                    id="sidebar-overlay"
                    role="presentation"
                    onClick={handleToggleMenuSidebar}
                    onKeyDown={() => {}}
                />
            </>
        );
    }, [isAppLoaded]);

    return <div className="wrapper">{getAppTemplate()}</div>;
};

export default Main;
