import {createSlice} from '@reduxjs/toolkit';

export const visitaSlice = createSlice({
    name: 'visita',
    initialState: {
        monitor: {
            vendedores: [],
            supervisores: []
        },
        clientes: {
            list: [],
            checkedValues: []
        },
        status: {
            listStatus: [],
            checkedValuesStatus: []
        },
        motivos: {
            listMotivos: [],
            checkedValuesMotivos: []
        },
        addVisit: {
            filterType: {value: 'manual'},
            filter: '',
            frequency: 1,
            status: 1,
            searchFor: 1,
            tipoPessoa: {value: 'any'}
        }
    },
    reducers: {
        setMonitorVendedores: (state, action) => {
            state.monitor.vendedores = action.payload;
        },
        setMonitorSupervisores: (state, action) => {
            state.monitor.supervisores = action.payload;
        },
        setClientes: (state, action) => {
            state.clientes.list = action.payload;
        },
        setCheckedValues: (state, action) => {
            state.clientes.checkedValues = action.payload;
        },
        setListStatus: (state, action) => {
            state.status.listStatus = action.payload;
        },
        setListStatusIds: (state, action) => {
            state.status.checkedValuesStatus = action.payload;
        },
        setListMotivos: (state, action) => {
            state.motivos.listMotivos = action.payload;
        },
        setListMotivosIds: (state, action) => {
            state.motivos.checkedValuesMotivos = action.payload;
        },
        setFilterType: (state, action) => {
            state.addVisit.filterType = action.payload;
        },
        setFilter: (state, action) => {
            state.addVisit.filter = action.payload;
        },
        setFrequency: (state, action) => {
            state.addVisit.frequency = action.payload;
        },
        setStatus: (state, action) => {
            state.addVisit.status = action.payload;
        },
        setSearchFor: (state, action) => {
            state.addVisit.searchFor = action.payload;
        },
        setTipoPessoa: (state, action) => {
            state.addVisit.tipoPessoa = action.payload;
        }
    }
});

export const {
    setMonitorVendedores,
    setMonitorSupervisores,
    setClientes,
    setCheckedValues,
    setFilterType,
    setFilter,
    setFrequency,
    setStatus,
    setSearchFor,
    setTipoPessoa,
    setListStatus,
    setListStatusIds,
    setListMotivos,
    setListMotivosIds
} = visitaSlice.actions;
