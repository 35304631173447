import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import {MenuItem} from '../../../components';
// import {MENU_CLIENTE} from './menu-cliente';
// import {MENU_SUPORTE} from './menu-suporte';
import LogoSIG from '../../../assets/images/sig_logo.png';
import useResizeObserver from '../../../hooks/observer';
import useMenu from '../../../hooks/menu/useMenu';

const MenuSidebar = () => {
    const user = useSelector((state) => state.auth.currentUser);
    const {width} = useResizeObserver('sidebar');
    const {menuMap} = useMenu();
    // const {isCliente} = useUsuarioLogado();
    // const MENU = isCliente() ? MENU_CLIENTE : MENU_SUPORTE;

    const styled = {
        aside: {backgroundColor: '#0F3161', position: 'fixed'},
        link: {display: 'flex', justifyContent: 'center'},
        sidebar: {height: '80%'},
        img: {
            opacity: '.8',
            transform: `scale(${width * 0.0085}, ${width * 0.0085})`,
            margin: '16px'
        }
    };

    return (
        <aside
            id="main-sidebar"
            className="main-sidebar elevation-3"
            style={styled.aside}
        >
            <Link to="/relatorios" className="brand-link" style={styled.link}>
                <img
                    src={LogoSIG}
                    alt="AdminLTE Logo"
                    className="brand-image"
                    id="logo-sig"
                    style={styled.img}
                />
                <br />
            </Link>
            <div id="sidebar" className="sidebar" style={styled.sidebar}>
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img
                            src={user.picture || '/img/default-profile.png'}
                            className="img-circle elevation-2"
                            alt="User"
                        />
                    </div>
                    <div className="info">
                        <Link
                            style={{color: 'lightgray'}}
                            to="/alterar-senha"
                            className="d-block"
                        >
                            {user.name}
                        </Link>
                    </div>
                </div>
                <nav className="mt-2" style={{overflow: 'hidden'}}>
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        role="menu"
                    >
                        {menuMap.map((menuItem) => (
                            <MenuItem
                                key={menuItem.name}
                                menuItem={menuItem}
                                submenuIcon={<SubdirectoryArrowRightIcon />}
                            />
                        ))}
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default MenuSidebar;
