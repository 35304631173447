import {createSlice} from '@reduxjs/toolkit';

export const grupoSlice = createSlice({
    name: 'grupo',
    initialState: {
        grupo: [],
        grupoIds: []
    },
    reducers: {
        grupoReducer: (state, action) => {
            state.grupo = action.payload;
        },
        grupoIdsReducer: (state, action) => {
            state.grupoIds = action.payload;
        }
    }
});

export const {grupoReducer, grupoIdsReducer} = grupoSlice.actions;
