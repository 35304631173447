import React from 'react';

const PrivacyPolicy = () => {
    return (
        <>
            <title>Política de Privacidade</title>
            <h1>Política de Privacidade do Aplicativo AFV</h1>

            <p>
                Esta política de privacidade descreve como o AFV coleta, usa e
                protege as informações pessoais dos usuários do nosso
                aplicativo. Ao utilizar o aplicativo AFV, você concorda com os
                termos desta política de privacidade.
            </p>

            <h2>Informações coletadas</h2>

            <p>
                Quando você utiliza o aplicativo AFV, podemos coletar as
                seguintes informações:
            </p>
            <ul>
                <li>
                    Informações do dispositivo, incluindo modelo, sistema
                    operacional e identificadores exclusivos
                </li>
                <li>
                    Informações de uso, incluindo páginas visitadas, tempo gasto
                    no aplicativo e recursos utilizados
                </li>
                <li>
                    Informações de localização, se você permitir o acesso a essa
                    informação
                </li>
            </ul>

            <h2>Como usamos suas informações</h2>

            <p>
                Utilizamos as informações coletadas para melhorar o
                funcionamento do aplicativo e personalizar a experiência do
                usuário. Além disso, podemos utilizar as informações para:
            </p>
            <ul>
                <li>Enviar notificações relevantes para o usuário</li>
                <li>
                    Realizar análises e pesquisas para melhorar nossos produtos
                    e serviços
                </li>
                <li>Fornecer suporte ao cliente</li>
                <li>Cumprir obrigações legais</li>
            </ul>

            <h2>Compartilhamento de informações</h2>

            <p>
                Compartilhamos informações específicas para aprimorar a
                eficiência e o suporte ao vendedor. Dentre essas informações,
                inclui-se a localização em segundo plano, a qual é compartilhada
                com o supervisor responsável pelo acompanhamento das atividades
                do vendedor que utiliza o aplicativo. Esse compartilhamento visa
                otimizar a gestão de equipe e garantir um suporte mais eficaz.
                Reforçamos que tal prática ocorre estritamente dentro dos
                limites legais e com a devida autorização do usuário.
            </p>

            <h2>Segurança das informações</h2>

            <p>
                Tomamos medidas para proteger suas informações pessoais de
                acesso não autorizado, uso ou divulgação. No entanto, não
                podemos garantir a segurança das informações transmitidas pela
                internet.
            </p>

            <h2>Alterações na política de privacidade</h2>

            <p>
                Reservamos o direito de alterar esta política de privacidade a
                qualquer momento, sem aviso prévio. Se fizermos alterações,
                publicaremos a nova política de privacidade no aplicativo e
                atualizaremos a data de revisão.
            </p>

            <h2>Contato</h2>

            <p>
                Se tiver perguntas ou preocupações sobre esta política de
                privacidade, entre em contato conosco pelo endereço de e-mail em
                nosso aplicativo.
            </p>
        </>
    );
};

export default PrivacyPolicy;
