import React from 'react';

const NewIcon = () => {
    return (
        <span
            style={{
                marginLeft: '10px',
                padding: '0px 5px',
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: '#1C2B56',
                borderRadius: '5px',
                border: '1px solid white'
            }}
        >
            novo
        </span>
    );
};

export default NewIcon;
