import {createSlice} from '@reduxjs/toolkit';

export const clienteStatusSlice = createSlice({
    name: 'clienteStatus',
    initialState: {
        clienteStatus: [],
        clienteStatusIds: []
    },
    reducers: {
        clienteStatusReducer: (state, action) => {
            state.clienteStatus = action.payload;
        },
        clienteStatusIdsReducer: (state, action) => {
            state.clienteStatusIds = action.payload;
        }
    }
});

export const {clienteStatusReducer, clienteStatusIdsReducer} =
    clienteStatusSlice.actions;
