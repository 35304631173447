import {createSlice} from '@reduxjs/toolkit';

export const sincSlice = createSlice({
    name: 'sinc',
    initialState: {
        filters: {
            lote: 0,
            qtdDiasNota: 0,
            vendProduto: 0,
            tabPreco: 0,
            vendCliente: 0,
            supervisorCliente: 0
        },
        periodo: {
            dia: '',
            hora: ''
        },
        checkboxes: {
            programada: [],
            basico: [],
            cliente: [],
            produto: [],
            vendedor: [],
            metodoPag: [],
            geral: []
        },
        programada: [],
        basico: [],
        cliente: [],
        produto: [],
        vendedor: [],
        metodoPag: [],
        favorites: [],
        geral: []
    },
    reducers: {
        setDia: (state, {payload}) => {
            state.periodo.dia = payload;
        },
        setHora: (state, {payload}) => {
            state.periodo.hora = payload;
        },
        setBasico: (state, {payload}) => {
            state.basico = payload;
        },
        setGeral: (state, {payload}) => {
            state.geral = payload;
        },
        setProgramada: (state, {payload}) => {
            state.programada = payload;
        },
        setCliente: (state, {payload}) => {
            state.cliente = payload;
        },
        setProduto: (state, {payload}) => {
            state.produto = payload;
        },
        setVendedor: (state, {payload}) => {
            state.vendedor = payload;
        },
        setMetodoPag: (state, {payload}) => {
            state.metodoPag = payload;
        },
        setFavorites: (state, {payload}) => {
            state.favorites = payload;
        },

        setLote: (state, {payload}) => {
            state.filters.lote = payload;
        },
        setQtdDiasNota: (state, {payload}) => {
            state.filters.qtdDiasNota = payload;
        },
        setVendProduto: (state, {payload}) => {
            state.filters.vendProduto = payload;
        },
        setTabPreco: (state, {payload}) => {
            state.filters.tabPreco = payload;
        },
        setVendCliente: (state, {payload}) => {
            state.filters.vendCliente = payload;
        },
        setSupervisorCliente: (state, {payload}) => {
            state.filters.supervisorCliente = payload;
        },

        setProgramadaChecks: (state, {payload}) => {
            state.checkboxes.programada = payload;
        },
        setBasicoChecks: (state, {payload}) => {
            state.checkboxes.basico = payload;
        },
        setClienteChecks: (state, {payload}) => {
            state.checkboxes.cliente = payload;
        },
        setProdutoChecks: (state, {payload}) => {
            state.checkboxes.produto = payload;
        },
        setVendedorChecks: (state, {payload}) => {
            state.checkboxes.vendedor = payload;
        },
        setMetodoPagChecks: (state, {payload}) => {
            state.checkboxes.metodoPag = payload;
        },
        setGeralChecks: (state, {payload}) => {
            state.checkboxes.geral = payload;
        }
    }
});

export const {
    setDia,
    setHora,

    setBasico,
    setGeral,
    setProgramada,
    setCliente,
    setProduto,
    setVendedor,
    setMetodoPag,
    setFavorites,

    setLote,
    setQtdDiasNota,
    setVendProduto,
    setTabPreco,
    setVendCliente,
    setSupervisorCliente,

    setProgramadaChecks,
    setBasicoChecks,
    setClienteChecks,
    setProdutoChecks,
    setVendedorChecks,
    setMetodoPagChecks,
    setGeralChecks
} = sincSlice.actions;
