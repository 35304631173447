import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import customTheme from '../../../theme/CustomTheme';

const CustomButton = styled(Button)(() => ({
    width: '150px',
    marginTop: '50px',
    backgroundColor: customTheme.palette.primary.main,
    color: customTheme.palette.typography.main,
    '&:hover': {
        color: customTheme.palette.primary.main,
        backgroundColor: 'lightblue'
    },
    '&:disabled': {
        backgroundColor: 'darkgray'
    }
}));

export default CustomButton;
