import {createSlice} from '@reduxjs/toolkit';

export const produtoStatusSlice = createSlice({
    name: 'produtoStatus',
    initialState: {
        produtoStatus: [],
        produtoStatusIds: []
    },
    reducers: {
        produtoStatusReducer: (state, action) => {
            state.produtoStatus = action.payload;
        },
        produtoStatusIdsReducer: (state, action) => {
            state.produtoStatusIds = action.payload;
        }
    }
});

export const {produtoStatusReducer, produtoStatusIdsReducer} =
    produtoStatusSlice.actions;
