/* eslint-disable no-unused-vars */
import Swal from 'sweetalert2';
import {useSelector} from 'react-redux';
import Sucessfully from '../components/sweetalert2/Sucessfully';
import {URL_API} from '../constants';
import {useAuth} from '../hooks/useAuth';
import {axiosInstance} from './AxiosClient';
import useHandleError from './useHandleError';
import lruCache from '../services/cache';
import Confirm from '../components/sweetalert2/Confirm';
import customTheme from '../theme/CustomTheme';

const useAPI = () => {
    const {headers, removeToken} = useAuth();
    const {handle} = useHandleError(removeToken);

    const cache = useSelector((state) => state.config.manager.cache);

    const containsProtocol = (rota) => /http:\/\/|https:\/\//g.test(rota);

    const handleParams = (params) => {
        const dtInicio = params.dtInicio ?? params.dataInicio;
        const dtFim = params.dtFim ?? params.dataFim;
        if (params.dtInicio !== undefined) {
            params = {
                ...params,
                dtInicio: JSON.stringify(dtInicio).split('.')[0],
                dtFim: JSON.stringify(dtFim).split('.')[0]
            };
        }
        return params;
    };

    const postSaveData = async (options, message) => {
        let res;

        await axiosInstance
            .request(options)
            .then(async (response) => {
                if (message !== null) Swal.fire(Sucessfully(message));
                else if (Object.keys(response?.data).length > 0)
                    Swal.fire(Sucessfully(response?.data?.message));
                res = response;
            })
            .catch((error) => {
                handle(error);
            });

        return res;
    };

    const postFilterData = async (params, rota, data, options) => {
        let res;

        params = handleParams(params);

        const key = rota + JSON.stringify(params) + JSON.stringify(data);

        const dataCache = lruCache.get(key);

        if (dataCache && cache) return dataCache;

        await axiosInstance
            .request(options)
            .then(async (response) => {
                res = response;
                if (res.data?.error === true) {
                    handle(res);
                }

                if (cache) lruCache.set(key, response);
            })
            .catch((catchError) => {
                handle(catchError);
            });

        return res;
    };

    const api = {
        async get(
            /** @type {string} */ rota,
            /** @type {JsonObjectExpression} */ params = {'': ''},
            /** @type {JsonObjectExpression} */ data = null,
            /** @type {string} */ responseType = null,
            /** @type {JsonObjectExpression} */ headersParam = headers
        ) {
            /** @type {import('axios').AxiosResponse} */
            let res;
            const options = {
                method: 'GET',
                url: containsProtocol(rota) ? rota : URL_API + rota,
                params,
                data,
                headers: headersParam,
                responseType
            };

            params = handleParams(params);

            const key = rota + JSON.stringify(params) + JSON.stringify(data);

            const dataCache = lruCache.get(key);

            if (dataCache && cache) return dataCache;

            await axiosInstance
                .request(options)
                .then(async (response) => {
                    res = response;
                    if (res.data?.error === true) {
                        handle(res);
                    }

                    if (cache) lruCache.set(key, response);
                })
                .catch((catchError) => {
                    handle(catchError);
                });

            return res;
        },

        async post(
            /** @type {string} */ rota,
            /** @type {T} */ obj = null,
            /** @type {string} */ message = null,
            /** @type {JsonObjectExpression} */ params = {'': ''},
            /** @type {JsonObjectExpression} */ headersParam = headers,
            /** @type {number} */ type = 0
        ) {
            /** @type {import('axios').AxiosResponse} */
            const options = {
                method: 'POST',
                url: URL_API + rota,
                params,
                headers: headersParam,
                data: obj
            };

            if (type === 0) {
                return postSaveData(options, message);
            }
            return postFilterData(params, rota, obj, options);
        },

        async patch(
            /** @type {string} */ rota,
            /** @type {T} */ obj,
            /** @type {string} */ message = null,
            /** @type {JsonObjectExpression} */ params = {'': ''},
            /** @type {JsonObjectExpression} */ headersParam = headers
        ) {
            /** @type {import('axios').AxiosResponse} */
            let res;
            const options = {
                method: 'PATCH',
                url: URL_API + rota,
                params,
                headers: headersParam,
                data: obj
            };

            await axiosInstance
                .request(options)
                .then(async (response) => {
                    if (message !== null) Swal.fire(Sucessfully(message));
                    res = response;
                })
                .catch((error) => {
                    handle(error);
                });

            return res;
        },

        /** @template T */
        async put(/** @type {string} */ rota, /** @type {T} */ obj) {
            /** @type {import('axios').AxiosResponse} */
            let res;

            await axiosInstance.put(rota, obj).then((response) => {
                res = response;
            });
            return res;
        },

        async delete(
            /** @type {string} */ rota,
            /** @type {string} */ messageSucess = null,
            /** @type {JsonObjectExpression} */ params = {'': ''},
            /** @type {JsonObjectExpression} */ headersParam = headers
        ) {
            /** @type {import('axios').AxiosResponse} */
            let res;
            const options = {
                method: 'DELETE',
                url: URL_API + rota,
                params,
                headers: headersParam
            };

            await axiosInstance
                .request(options)
                .then(async (response) => {
                    Swal.fire(
                        Sucessfully(messageSucess || `Excluído com sucesso!`)
                    );
                    res = response;
                })
                .catch((error) => {
                    handle(error);
                });
            return res;
        },

        async deleteWithConfirm(
            /** @type {string} */ rota,
            /** @type {string} */ messageSucess = null,
            /** @type {function} */ functionDelete = () => {},
            /** @type {JsonObjectExpression} */ params = {'': ''},
            /** @type {JsonObjectExpression} */ headersParam = headers
        ) {
            /** @type {import('axios').AxiosResponse} */
            let res;
            const options = {
                method: 'DELETE',
                url: URL_API + rota,
                params,
                headers: headersParam
            };

            Swal.fire(Confirm(messageSucess)).then((result) => {
                if (result.isConfirmed) {
                    axiosInstance
                        .request(options)
                        .then(async (response) => {
                            Swal.fire({
                                title: 'Deletado com sucesso!',
                                icon: 'success',
                                confirmButtonColor:
                                    customTheme.palette.primary.main,
                                iconColor: customTheme.palette.success.main
                            });
                            res = response;
                            functionDelete();
                        })
                        .catch((error) => {
                            handle(error);
                        });
                }
                return res;
            });
        }
    };

    return {
        api
    };
};

export default useAPI;
