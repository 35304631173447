/* eslint-disable prettier/prettier, prefer-const */
import {useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import {axiosInstance} from '../api/AxiosClient';
import useHandleError from '../api/useHandleError';
import {MANAGER_V1_URL, URL_API} from '../constants';
import {nyan} from '../constants/Links';
import {QA_TESTER_TOKEN} from '../__tests__/api/token';
import useUIDeAtualizacao from './atualizacoes/useUIDeAtualizacao';

export const useAuth = (checkPassword = true, showToast = true) => {
    const history = useHistory();
    const {setAtualizacoesLocal} = useUIDeAtualizacao();
    const tokenType = useMemo(() => localStorage.getItem('token_type'));
    const accessToken = useMemo(() => localStorage.getItem('access_token'));
    const params = {'': ''};
    const [loginError, setLoginError] = useState();

    const headers = useMemo(() => {
        return {
            Authorization: process.env.JEST_WORKER_ID
                ? QA_TESTER_TOKEN
                : `${tokenType} ${accessToken}`
        };
    });

    const removeToken = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('token_type');
        localStorage.removeItem('isCliente');
        localStorage.removeItem('expires');
        localStorage.removeItem('info_user');
    };

    const {handle} = useHandleError(removeToken);

    const isLoggedIn = () => {
        if (
            localStorage.getItem('access_token') !== null &&
            new Date() < new Date(localStorage.getItem('expires'))
        ) {
            return true;
        }
        removeToken();
        return false;
    };

    const setToken = (response) => {
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('token_type', response.data.token_type);
        localStorage.setItem(
            'expires',
            new Date(new Date().getTime() + response.data.expires * 60000)
        );
    };

    const setCliente = (response) => {
        const infoUser = response.data.info_user;
        localStorage.setItem('isCliente', response.data.client);
        localStorage.setItem('nome_fantasia',response.data.nome_fantasia)
        localStorage.setItem('razao_social',response.data.razao_social)
        localStorage.setItem(
            'info_user',
            JSON.stringify({
                empresa: infoUser.fk_empresa,
                perfil: infoUser.fk_perfil,
                usuario: infoUser.usuario,
                tipo_empresa: infoUser.fk_tipo_empresa
            })
        );
        localStorage.setItem(
            'menu',
            JSON.stringify(
                infoUser.menus
                    // filtra os menus do central_afv do painel antigo
                    .filter((m) => m.id >= 107)
                    .map((m) => ({
                        id: m.id,
                        fk_menu: m.fk_menu,
                        extra: m.extra,
                        descricao: m.descricao,
                        url: m.url
                    }))
            )
        );
        const nyanUsers = [
            String.fromCharCode(116, 105, 97, 103, 111, 97, 110, 100, 114, 97, 100, 101),
            String.fromCharCode(106, 104, 111, 110, 121)
        ];
        // eslint-disable-next-line
        if (nyanUsers.includes(infoUser.usuario)) {
            Swal.fire({
                showConfirmButton: false,
                html: '<br><br><br><br><br>',
                background: `#fff url(${nyan})`,
                width: 400,
                padding: '3em',
                color: '#716add',
                backdrop: `
                  rgba(0,0,123,0.4)
                  url(${nyan})
                  right top
                  no-repeat
                `
            });
        }
        setAtualizacoesLocal();
    };

    const handlePassword = (value) => {
        let strength =
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/g;
        let test = strength.test(value);

        return {
            isStrong: () => test,
        };
    };

    const authMe = (res, values) => {
        const options = {
            method: 'POST',
            url: `${URL_API}v1/auth/me`,
            params,
            headers: {
                Authorization: `${res.data.token_type} ${res.data.access_token}`
            }
        };
        axiosInstance
            .request(options)
            .then((response) => {
                if (response.status === 200) {
                    setCliente(response);
                    setTimeout(() => {
                        if (! handlePassword(values?.password).isStrong() && checkPassword) {
                            history.push('/alterar-senha');
                        } else {
                            history.push('/relatorios');
                        }

                    }, 1000);
                }
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                toast.error(JSON.stringify(error));
            });
    };

    const loginPost = (values) => {
        const options = {
            method: 'POST',
            url: `${URL_API}v1/login`,
            params,
            headers,
            data: values
        };

        const promise = axiosInstance
            .request(options)
            .then((response) => {
                if (response.status === 200) {
                    setToken(response);
                    authMe(response, values);
                    setLoginError(undefined);
                }
            })
            .catch((error) => {
                setLoginError(error?.response);
                throw error;
            });

        if (showToast) {
            toast.promise(promise, {
                pending: 'Logando...',
                success: 'Login feito com sucesso!',
                error: promise.catch((error) => handle(error))
            }, {
                toastId: 'login'
            });
        }
    };

    const logout = () => {
        removeToken();
        const options = {
            method: 'POST',
            url: `${URL_API}v1/auth/logout`,
            params,
            headers
        };
        axiosInstance
            .request(options)
            .then(() => {
                window.location.reload();
                toast.error('Logout feito!');
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
        isLoggedIn();
        history.push('/login');
    };

    const getSecretKey = () => {
        const options = {
            method: 'POST',
            url: `${URL_API}v1/auth/secret_key`,
            params,
            headers
        };
        axiosInstance
            .request(options)
            .then((response) => {
                if (response.status === 200) {
                    const {secretKey} = response.data;

                    window.location = `${MANAGER_V1_URL}index.php/usuario_auth/login/${secretKey}`;
                }
            })
            .catch((error) => {
                setLoginError(error?.response);
                throw error;
            });
    };

    return {
        loginPost,
        logout,
        params,
        headers,
        isLoggedIn,
        removeToken,
        loginError,
        getSecretKey
    };
};
