const Columns = {
    CLIENTES: [
        'Cód. Cliente',
        'Razão Social',
        'CNPJ',
        'Status',
        'Cód. Vendedor',
        'Filial',
        'Atividade',
        'Nome Fantasia'
    ],
    EMPRESAS: [
        'Cód.',
        'CNPJ',
        'Razão Social',
        'Nome Fantasia',
        'Qtd. Licença',
        'Status',
        'Ação'
    ],
    INTEGRACOES: [
        'Cód.',
        'Empresa Matriz',
        'Empresa Filial',
        'URL Api',
        'URL Ecommerce',
        'Status',
        'Ação'
    ],
    ELEMENTOS: [
        'Cód.',
        'Parâmetro 1',
        'Parâmetro 2',
        'Parâmetro 3',
        'Código Externo',
        'Tipo',
        'Integrador',
        'Ação'
    ],
    DISPOSITIVOS: [
        'Id',
        'Vendedor',
        'Marca',
        'Modelo',
        'Versão - App',
        'Mac',
        'Utiliza horário?',
        'Status',
        'Ação'
    ],
    VERSAO: ['Cód.', 'Versão', 'Obrigatório', 'Observação', 'Ação'],
    USUARIO: [
        'Id',
        'Nome',
        'E-mail',
        'Telefone',
        'Usuário',
        'Perfil',
        'Status',
        'Ação'
    ],
    PERFIL: ['Id', 'Perfil', 'Tipo do Perfil', 'Status', 'Ação'],
    HISTORICO_COMPRAS: [
        'Id',
        'Filial',
        'Cliente',
        'Nome Fantasia',
        'Vendedor',
        'Pedido ERP',
        'N° da Nota',
        'Valor Pedido',
        'Valor Faturado',
        'Data do Pedido',
        'Data do Faturamento',
        'Status',
        'Info'
    ],
    NOTA_DETALHADA: [
        'Descrição',
        'Valor Unitário',
        'Desconto',
        'Pedido qtd.',
        'NFS qtd.',
        'Total Pedido',
        'Total Faturado',
        'Status'
    ],
    META: [
        'Filial',
        'Vendedor',
        'Descricao',
        'Qtd. Vendida',
        'Total em Peso',
        'Objetivo',
        'Total Vendido',
        '% Atingido',
        'Info'
    ],
    TITULO_FINANCEIRO: [
        'Doc.',
        'Cliente',
        'Valor Original',
        'Parcela',
        'Valor Titulo',
        'Multa/Juros',
        'Valor Atual',
        'Status Pgto.',
        'Status Venc.',
        'Forma Pgto.',
        'Emissão',
        'Vencimento',
        'Linha Digitável',
        'Compartilhar'
    ],
    GRAFICO_TITULOS: [
        'Clientes',
        'Valor Original',
        'Valor Titulo',
        'Multa/Juros',
        'Valor Atual'
    ],
    PRODUTO: [
        'Grupo',
        'Subgrupo',
        'Id',
        'Filial',
        'Cód. Produto',
        'Descrição',
        'Unidade',
        'Status'
    ],
    PEDIDOS_ANDAMENTO: [
        'Id Nuvem',
        'Filial',
        'Vendedor',
        'Cód. Cliente',
        'Pedido ERP',
        'Valor Total',
        'Tipo Pedido',
        'Data Emissão',
        'Data Recebimento',
        'Data Sinc.',
        'Status',
        'Origem',
        'Info'
    ],
    PEDIDOS_COORDENADAS: [
        'Filial',
        'Vendedor',
        'Pedidos C/C',
        'Pedidos S/C',
        'Total de Pedidos',
        'Positivação',
        'Ticket Médio',
        'Valor Total',
        'Info',
        'Mapa'
    ],
    PEDIDOS_COORDENADAS_CID: [
        'Ibge',
        'Estado',
        'Cidade',
        'Região',
        'Qtd. Pedidos',
        'Valor'
    ],
    PEDIDOS_COORDENADAS_EST: [
        'Sigla',
        'Estado',
        'Região',
        'Qtd. Pedidos',
        'Valor'
    ],
    LISTAR_SENHA: [
        'Id',
        'Gerada por',
        'Solicitado por',
        'MAC',
        'Motivo',
        'Data',
        'Observação'
    ],
    LIBERADOS_POR_SENHA: [
        'Pedido AFV',
        'Liberado por',
        'Solicitado por',
        'Senha Utilizada',
        'Motivo',
        'Observação',
        'Distância do Cliente',
        'Data Emissão',
        'Info'
    ],
    LISTAR_SENHA_PEDIDO: [
        'Pedido AFV / WEB:',
        'Liberado por',
        'Solicitado por',
        'Senha utilizada',
        'Motivo',
        'Observação',
        'Distância do Cliente',
        'Data de Emissão'
    ],
    LIBERADOS_POR_SENHA_DETALHADO: [
        'Id',
        'Foto (url)',
        'Descrição',
        'Unidade',
        'Unitário',
        'IPI',
        'ST',
        'Frete',
        'Seguro',
        'Verba',
        'Desconto',
        'Qtd.',
        'Total'
    ],
    PEDIDO_DETALHADO: [
        'Ordem',
        'Foto',
        'Descrição',
        'Unidade',
        'Qtd.',
        'Unitário',
        'IPI',
        'ST',
        'Frete',
        'Seguro',
        'Verba',
        'Desconto',
        'Total'
    ],
    PEDIDOS_VENDEDOR: [
        'Filial',
        'Pedido AFV / WEB',
        'Cliente',
        'Data e Hora',
        'Valor',
        'Detalhes'
    ],
    GRUPOS: ['Cód.', 'Empresa', 'Descrição', 'Status', 'Ação'],
    RELATORIO: [
        'Cód.',
        'Grupo',
        'Imagem',
        'Título',
        'Slug',
        'Gráfico',
        'Status',
        'Usuário Cad.',
        'Cadastro',
        'Usuário Alt.',
        'Alteração',
        'SQL',
        'DataKey',
        'Ação'
    ],
    TIPO_GRAFICO: ['Cód.', 'Descrição', 'Status', 'Ação'],
    ORDEM_VISITA: ['Ordem', 'Cliente', 'Data', 'Endereço'],
    MARGEM_MARKUP: ['Descrição', 'Custo', 'Rentabilidade', 'Margem', 'Markup'],
    VISITA: [
        'Marcar',
        'ID Nuvem',
        'Razão Social',
        'ID Retaguarda',
        'Filial',
        'Prioridade'
    ],
    DETALHAMENTO_AFV: [
        'Filial',
        'Cód. ERP',
        'Vendedor',
        'Valor Pedido',
        'Faturado',
        'Data do Pedido',
        'Data do Faturamento',
        'Status'
    ],
    SERVICO_DAS_EMPRESAS: ['Cód.', 'Empresa', 'Status'],
    DASHBOARD: [
        'Nome',
        'Nº pedidos',
        'Valor',
        '% Sobre total',
        '% Acumulado',
        'Peso líquido',
        'Peso bruto'
    ],
    PRAZO_PAGAMENTO: [
        'Prazo de pagamento',
        'Descrição',
        'Valor mínimo',
        'Variação',
        'Status'
    ],
    TABELA_PRECO: [
        'Cód. tabela de preço',
        'Descrição',
        'Data inicial',
        'Data final',
        'Gera verba',
        'Filial'
    ],
    FORMAS_PAGAMENTO: [
        'Modo de cobrança',
        'Descrição',
        'Valor mínimo',
        'Situação',
        'Status'
    ]
};

export default Columns;
