import React from 'react';
import './forgot-pass.css';
import {Link, withRouter} from 'react-router-dom';
import {Box, CardMedia, Grid, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import RedeSocial from '../../components/Rede-social';
import Input from '../../components/input/Input';
import img from '../../assets/images/logo.png';
import CustomButton from '../../components/mui/button/CustomButton';
import useNovaSenha from '../../hooks/configuracoes/useNovaSenha';
import TokenInvalido from './TokenInvalido';
import CenterColumnBox from '../../components/mui/wrapper/CenterColumnBox';

const NovaSenha = () => {
    const {formik, tokenIsValid} = useNovaSenha();

    const InterfaceElement = (
        <div className="pass-container">
            <div className="pass-card">
                <Paper
                    elevation={3}
                    sx={{
                        padding: '20px',
                        borderRadius: '10px'
                    }}
                >
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        width="480px"
                        sx={{
                            maxWidth: '300px',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <CardMedia
                            sx={{
                                marginTop: '20px',
                                maxWidth: '150px'
                            }}
                            component="img"
                            src={img}
                        />
                        <form
                            style={{marginTop: '20px'}}
                            noValidate
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="text-input">
                                <Input
                                    icon={faLock}
                                    placeholder="Nova Senha"
                                    type="password"
                                    formik={formik}
                                    formikFieldProps={formik.getFieldProps(
                                        'password'
                                    )}
                                />
                            </div>
                            <div className="text-input">
                                <Input
                                    icon={faLock}
                                    placeholder="Confirmar Nova Senha"
                                    type="password"
                                    formik={formik}
                                    formikFieldProps={formik.getFieldProps(
                                        'password_confirmation'
                                    )}
                                />
                            </div>
                            <br />
                            <div>
                                <CustomButton
                                    className="recovery-button"
                                    type="submit"
                                    sx={{marginTop: '10px', width: '100%'}}
                                >
                                    Enviar
                                </CustomButton>
                            </div>
                        </form>
                    </Grid>
                    <div style={{padding: '30px'}} className="return-login">
                        <Link to="/login">
                            <small>Retornar ao Login</small>
                        </Link>
                    </div>
                </Paper>
            </div>
            <div>
                <RedeSocial />
            </div>
        </div>
    );

    const TokenInvalidoElement = (
        <Box sx={{paddingTop: '20vh'}}>
            <CenterColumnBox height="60vh">
                <TokenInvalido />
                <Typography variant="h5" sx={{pl: '8px', textAlign: 'center'}}>
                    Seu token não é valido. Volte para a tela de recuperação de
                    senha e solicite o email.
                </Typography>
                <Link to="/recuperar-senha">
                    <CustomButton sx={{marginTop: '20px', width: '100px'}}>
                        Voltar
                    </CustomButton>
                </Link>
            </CenterColumnBox>
        </Box>
    );

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{tokenIsValid() ? InterfaceElement : TokenInvalidoElement}</>;
};

export default withRouter(NovaSenha);
