import {createTheme, useMediaQuery, useTheme} from '@mui/material';

const useBreakpoint = () => {
    const theme = useTheme();
    const customTheme = createTheme({
        breakpoints: {
            values: {
                ...theme.breakpoints.values,
                tablet: 1112,
                screen1450: 1450
            }
        }
    });
    const isSmartphone = useMediaQuery(customTheme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(customTheme.breakpoints.down('tablet'));
    const isLessThan1450px = useMediaQuery(
        customTheme.breakpoints.down('screen1450')
    );

    return {
        isSmartphone,
        isTablet,
        isLessThan1450px
    };
};

export default useBreakpoint;
