import React from 'react';
import invalidSvg from '../../assets/images/undraw_void_-3-ggu.svg';

const TokenInvalido = () => {
    return (
        <img
            src={invalidSvg}
            style={{maxHeight: '50%', paddingBottom: '30px'}}
            alt=""
        />
    );
};

export default TokenInvalido;
