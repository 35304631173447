import {createSlice} from '@reduxjs/toolkit';

export const atividadeClienteSlice = createSlice({
    name: 'atividadecliente',
    initialState: {
        atividadeCliente: [],
        atividadeClienteIds: []
    },
    reducers: {
        atividadeClienteReducer: (state, action) => {
            state.atividadeCliente = action.payload;
        },
        atividadeClienteIdsReducer: (state, action) => {
            state.atividadeClienteIds = action.payload;
        }
    }
});

export const {atividadeClienteReducer, atividadeClienteIdsReducer} =
    atividadeClienteSlice.actions;
