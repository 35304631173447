import React from 'react';
import LoadingSIG from '../loading';
import classes from './PageLoading.module.scss';

const PageLoading = () => {
    return (
        <div className={classes.loading}>
            <LoadingSIG />
        </div>
    );
};

export default PageLoading;
