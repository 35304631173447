// eslint-disable-next-line no-unused-vars
import {SweetAlertOptions} from 'sweetalert2';
import customTheme from '../../theme/CustomTheme';

// @ts-check
/** @returns {SweetAlertOptions} */
const Confirm = (/** @type {string} */ text) => {
    return {
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: customTheme.palette.primary.main,
        cancelButtonColor: '#d33',
        confirmButtonText: 'Deletar',
        iconColor: customTheme.palette.warning.main
    };
};

export default Confirm;
