import {createSlice} from '@reduxjs/toolkit';

const manager = JSON.parse(localStorage.getItem('manager'));

export const configSlice = createSlice({
    name: 'config',
    initialState: {
        forcaDeVendas: {
            configPadrao: []
        },
        pedidoWeb: {
            config: []
        },
        manager: {
            cache: manager?.cache ?? false
        },
        valoresSeguro: {
            estados: []
        }
    },
    reducers: {
        setConfigPadrao: (state, {payload}) => {
            payload.sort((a, b) => {
                if (a.tipo < b.tipo) return -1;
                if (a.tipo > b.tipo) return 1;
                return 0;
            });
            state.forcaDeVendas.configPadrao = payload;
        },
        setConfigPedidoWeb: (state, {payload}) => {
            state.pedidoWeb.config = payload;
        },
        setCache: (state, action) => {
            state.manager.cache = action.payload;
        },
        setEstados: (state, {payload}) => {
            state.valoresSeguro.estados = payload;
        }
    }
});

export const {setCache, setConfigPadrao, setConfigPedidoWeb, setEstados} =
    configSlice.actions;
