import React from 'react';
import './login.css';
import {Link, withRouter} from 'react-router-dom';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import {faLock, faUser} from '@fortawesome/free-solid-svg-icons';
import * as Yup from 'yup';
import {CardMedia, Grid} from '@mui/material';
import Paper from '@mui/material/Paper';
import RedeSocial from '../../components/Rede-social';
import {Input} from '../../components';
import img from '../../assets/images/logo.png';
import useYupValidation from '../../hooks/validation/useYupValidation';
import {useAuth} from '../../hooks/useAuth';
import CustomButton from '../../components/mui/button/CustomButton';

const Login = () => {
    const [t] = useTranslation();
    const {requiredStringField} = useYupValidation();
    const {loginPost, loginError} = useAuth();

    const formik = useFormik({
        initialValues: {
            usuario: '',
            password: ''
        },
        validationSchema: Yup.object({
            usuario: requiredStringField('Usuário'),
            password: requiredStringField('Senha')
        }),
        onSubmit: (values) => {
            loginPost(values);
        }
    });

    return (
        <div className="login-container">
            <div className="login-card">
                <Paper
                    elevation={3}
                    sx={{
                        padding: '20px',
                        borderRadius: '10px'
                    }}
                >
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        width="480px"
                        sx={{
                            maxWidth: '300px',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <CardMedia
                            sx={{
                                marginTop: '20px',
                                maxWidth: '150px'
                            }}
                            component="img"
                            src={img}
                        />
                        <form onSubmit={formik.handleSubmit}>
                            <div className="custom-field">
                                <Input
                                    icon={faUser}
                                    placeholder="Usuário"
                                    type="text"
                                    formik={formik}
                                    formikFieldProps={formik.getFieldProps(
                                        'usuario'
                                    )}
                                    loginError={loginError}
                                />
                            </div>
                            <div className="custom-field">
                                <Input
                                    icon={faLock}
                                    placeholder="Senha"
                                    type="password"
                                    formik={formik}
                                    formikFieldProps={formik.getFieldProps(
                                        'password'
                                    )}
                                    loginError={loginError}
                                />
                            </div>
                            <div className="custom-field">
                                <CustomButton
                                    sx={{width: '100%', marginTop: '15px'}}
                                    block
                                    type="submit"
                                >
                                    {t('login.button.signIn.label')}
                                </CustomButton>
                            </div>
                            <div
                                style={{padding: '15px'}}
                                className="custom-field"
                            >
                                <Link to="/recuperar-senha">
                                    {t('login.label.forgotPass')}
                                </Link>
                            </div>
                        </form>
                    </Grid>
                </Paper>
            </div>
            <RedeSocial />
        </div>
    );
};

export default withRouter(Login);
